export default [
	// Manage Partner
	{
		path: 'manage-partner',
		component: () => import('@/views/partner/ManagePartnerTable.vue'),
		meta: {
			title: 'Manage Partner',
			isSubmenu: true,
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Partner'},
				{ name: 'Manage Partner'}
			]
		}
	},
	{
		path: 'manage-partner/detail/:id',
		component: () => import('@/views/partner/ManagePartnerDetail.vue'),
		meta: {
			title: 'Detail Partner',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Partner', link: '/partner/manage-partner'},
				{ name: 'Manage Partner', link: '/partner/manage-partner'},
				{ name: 'Detail Partner'}
			]
		}
	},

	// Admin Fee
	{
		path: 'admin-fee',
		component: () => import('@/views/partner/AdminFeeTable.vue'),
		meta: {
			title: 'Admin Fee',
			isSubmenu: true,
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Partner'},
				{ name: 'Admin Fee'}
			]
		}
	},
	{
		path: 'admin-fee/new',
		component: () => import('@/views/partner/AdminFeeForm.vue'),
		meta: {
			title: 'New Admin Fee',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Partner', link: '/partner/admin-fee'},
				{ name: 'Admin Fee', link: '/partner/admin-fee'},
				{ name: 'New'}
			]
		}
	}
]