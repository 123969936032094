const LimitItem = 10

export const tabledata = {
  namespaced: true,
  state: {
		attr: {
			totalPage:1,
			current:1, 
			pages: [],
			pageSize: 9,
			fromNum: 1,
			toNum: 1,
			nextPage: 1,
			prevPage: 1,
			totalRequest: 0,
			displayPage: LimitItem
		},
		form: {
			default: {
				page: 1,
				limit: LimitItem,
				sort: 'DESC',
				searchTerm: ''
			},
			merchant: {
				page: 1,
				limit: LimitItem,
				sort: 'DESC',
				searchTerm: '',
				startDate: '',
				endDate: '',
				mid: '',
			},
			rangedate: {
				page: 1,
				limit: LimitItem,
				sort: 'DESC',
				searchTerm: '',
				startDate: '',
				endDate: ''
			},
			manualtransfer: {
				page: 1,
				limit: LimitItem,
				sort: 'DESC',
				searchTerm: '',
				startDate: '',
				endDate: '',
				mid: '',
				gatewayName: ''
			},
			kyc: {
				pageNumber: 1,
				pageSize: LimitItem
			}
		}
	},
	getters: {
		attr: (state) => {
			let attrs = state.attr

			attrs.fromNum = 1;
			attrs.toNum = attrs.totalPage;

			if(attrs.totalPage > attrs.pageSize){
				let marginTop = 4
				let marginBottom = attrs.totalPage - 4

				if(attrs.current < marginTop) {
					attrs.fromNum = 1
					attrs.toNum = marginTop
				}
				else if (attrs.current > marginBottom) {
					attrs.fromNum = marginBottom
				}
				else {
					attrs.fromNum = parseInt(attrs.current) - 3
					attrs.toNum = parseInt(attrs.current) + 3
				}
			}
			
			let pages = []
			for(let i=attrs.fromNum; i<=attrs.toNum; i++) {
				pages.push(i)
			}
			attrs.pages = pages
			return attrs
		},
		rowsCount: (state) => state.attr.totalRequest
	},
  mutations: {
    pageAttribute(state, totalRequest) {
			let totalPage = Math.ceil(parseInt(totalRequest) / parseInt(state.attr.displayPage))
      state.attr.totalRequest = totalRequest;
      state.attr.totalPage = totalPage;
			state.attr.toNum = totalPage;
    },
		gotoPage(state, data) {
			state.form[data.form].page = data.pagenum
			state.attr.current = data.pagenum;
      state.attr.nextPage = parseInt(data.pagenum) + 1;
      state.attr.prevPage = parseInt(data.pagenum) - 1;
		},
		gotoPageKYC(state, data) {
			state.form.kyc.pageNumber = data
			state.attr.current = data;
      state.attr.nextPage = parseInt(data) + 1;
      state.attr.prevPage = parseInt(data) - 1;
		},
		resetForm(state, formname) {
			state.form[formname].page = 1
			state.form[formname].limit = LimitItem
			state.form[formname].sort = 'DESC'
			state.form[formname].searchTerm = ''

			if('startDate' in state.form[formname]) state.form[formname].startDate = ''
			if('endDate' in state.form[formname]) state.form[formname].endDate = ''
			if('mid' in state.form[formname]) state.form[formname].mid = ''
			if('gatewayName' in state.form[formname]) state.form[formname].gatewayName = ''
		},
		resetFormKYC(state) {
			state.form.kyc = {
				pageNumber: 1,
				pageSize: LimitItem
			}
		},
		setFilterData(state, data) {
			state.form[data.form].page = 1
			state.form[data.form].searchTerm = data.data.searchTerm || ""

			if('startDate' in state.form[data.form])  state.form[data.form].startDate = data.data.startDate || ""
			if('endDate' in state.form[data.form]) state.form[data.form].endDate = data.data.endDate || ""
			if('mid' in state.form[data.form]) state.form[data.form].mid = data.data.mid || ""
			if('gatewayName' in state.form[data.form]) state.form[data.form].gatewayName = data.data.gatewayName || ""
		}
  }
};