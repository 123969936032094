import { createStore } from "vuex";
import { auth } from "./auth.module";
import { dashboard } from "./dashboard.module";
import { tabledata } from "./tabledata.common";
import { BankMaster, Merchants, Popup, Preload } from "./masterdata.module";
import { kyc } from "./kyc.module";
import { merchantCashout, manualTransfer } from "./merchants-cashout.module";
import { ManagePartner, AdminFee } from "./partner.module";


const store = createStore({
  modules: {
    auth,
    dashboard,
    tabledata,

    // Master Data
    BankMaster,
    Merchants,
    Popup,
    Preload,

    // KYC
    kyc,

    // Merchant Cashout
    merchantCashout,
    manualTransfer,

    // Partner
    ManagePartner,
    AdminFee
  },
});

export default store;