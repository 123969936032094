import _connection from '@/services/_connection'

const RESOURCE_NAME = 'default'

export default {
	// KYC
	getListKYC(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'User/DownloadKyc', payload)
	},
	exportKYC(query) {
		return _connection.getRequest(RESOURCE_NAME, 'User/DownloadFileKyc', query)
	},
	getDetailKYC(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'User/KYCDetail', payload)
	},
	acceptKYC(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'User/ApprovalKYC', payload)
	},
	declineKYC(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'User/RejectKYC', payload)
	},

	// Merchant List
	getMerchants(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'Merchant/MerchantList', payload)
	}
}
