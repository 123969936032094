<template>
	<li class="nav-item" v-for="route in routesInfo" 
		v-bind:key="route.path" 
		:class = "[$route.path == route.path ? 'active' : '']"
		>
			<div v-if="route.meta.hasSubmenu"> 
				<router-link to="#" class="nav-link" @click='submenuToggle(route.name)'>
					<div :class="route.meta.icon"></div>
					<span>{{route.meta.title}}</span>
					<i class="arrow" :class="!dropdownMenus[route.name] ? 'down' : 'up'"></i>
				</router-link>
				<div v-if='dropdownMenus[route.name]'>
					<div class="nav-submenu" v-for="childRoute in route.children.filter(filterMenu)" 
						v-bind:key="childRoute.path"
						:class = "[(route.path + '/' + childRoute.path) == $route.path ? 'active' : '']">
							<router-link :to="route.path + '/' + childRoute.path" class="nav-submenu-link">
								<span>{{childRoute.meta.title}}</span>
							</router-link>
					</div>
				</div>
			</div>
			<div v-else-if="!route.meta.hidden">
				<router-link :to="route.path" class="nav-link">
					<div :class="route.meta.icon"></div>
					<span>{{route.meta.title}}</span>
				</router-link>
			</div>
    </li>
</template>

<script>
	export default {
		name: 'SidebarMenuComponent',
		data () {
			return {
				dropdownMenus: {
					'merchantscashout' : false
				}
			}
		},
		computed: {
			routesInfo: function() {
				return this.$router.options.routes;
			}
		},
		methods: {
			submenuToggle(menuname) {
				this.dropdownMenus[menuname] = !this.dropdownMenus[menuname] 
			}, 
			filterMenu(child) {
				let showMenu = true;

				// filter submenu
				if(child.meta.isSubmenu != true){
					showMenu = false
				}

				// filter roles
				if(child.meta.roles) {
					const user_roles = this.$store.state.auth.user.roles;
					const route_roles = child.meta.roles
					const validate_roles = route_roles.some(role => user_roles.includes(role))

					if(!validate_roles) {
						showMenu = false
					}
				}

				return showMenu
			}
		}
	}
</script>