<template>
  <ul class="navbar-nav bg-gradient-sidebar sidebar sidebar-dark accordion" id="accordionSidebar">
    <SidebarBrand />
    <SidebarMenu />
  </ul>
</template>

<script>
import SidebarBrand from '@/components/layouts/SidebarBrandComponent.vue'
import SidebarMenu from '@/components/layouts/SidebarMenuComponent.vue'

export default {
  name: "MainSidebarLayout",
  components: {
    SidebarBrand,
    SidebarMenu
  }
}
</script>