import DefaultService from '@/services/default.service';
import helper from '@/plugins/helper'

export const kyc = {
  namespaced: true,
  state: {
		data: {
			items: [],
			label: {
				table: [
					{name: 'email', label: 'Email'},
					{name: 'merchant_name', label: 'Merchant'},
					{name: 'phoneNumber', label: 'Phone Number'},
					{name: 'registrationDate', label: 'Registration Date'},
					{name: 'status', label: 'Status'},
					{name: 'link', label: 'Action', isLinked: true, linklabel: 'View'}
				]
			}
		},
		detail:{
			items:[],
			loading: false,
			label: {
				info: [
					{ name: 'merchant_name', label: 'Merchant' },
					{ name: 'firstName', label: 'First Name' },
					{ name: 'lastName', label: 'Last Name' },
					{ name: 'email', label: 'Email' },
					{ name: 'phoneNumber', label: 'Phone Number' },
					{ name: 'npwp', label: 'NPWP' },
					{ name: 'requestDate', label: 'Request Date' }
				],
				images: [
					{name:'ktpImage', label:'KTP', type: 'base64'},
					{name:'selfieImage', label:'Selfie', type: 'base64'},
				],
				popup: {
					approval: {
						submitTitle: "KYC Application Accepted",
						submitSubtitle: "KYC Application successfully accepted!"
					},
					rejection: {
						formTitle: "Decline Application",
						submitTitle: "KYC Application Declined",
						submitSubtitle: "Reason for declining : "
					}
				},
				status: {
					approval: [ {name:'kycStatus', label:'Status'} ],
					rejection: [ {name:'kycStatus', label:'Status'}, {name:'rejectReason', label:'Reason'} ],
				}
			},
			options: {
				reasons:['Picture not clear', "Selfie didn't match", "NIK didn't match", "Other"]
			}
		}
	},
	getters: {
		dataItems: (state, getters, rootState) => {
			let dataItems = null
			let data = state.data.items
			
			if(data !== null) {
				let mutatedData = data.map(mutateData => {
					mutateData.link = helper.encrypt(mutateData.phoneNumber, true)

					let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == mutateData.mid)
					mutateData.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : mutateData.mid

					return mutateData
				})
				dataItems = mutatedData
			} 

			return dataItems
		},
		dataLabel: state => state.data.label,
		dataForm: (state, getters, rootState) => {
			let dataform = rootState.tabledata.form.kyc
			dataform.adminPhoneNumber = rootState.auth.user.detail.adminPhoneNumber
			dataform.adminPassword = rootState.auth.user.detail.adminPin
			return dataform
		},
		detailLoading: state => state.detail.loading,
		detailItems: (state, getters, rootState) => {
			let items = state.detail.items
			let detailItems = {
				phoneNumber: helper.getValue(items, 'phoneNumber'),
				firstName: helper.getValue(items, 'firstName'),
				lastName: helper.getValue(items, 'lastName'),
				mid: helper.getValue(items, 'mid'),
				kycStatus: helper.getValue(items, 'kycStatus'),
				rejectReason: helper.getValue(items, 'rejectReason'),
				email: helper.getValue(items, 'email'),
				requestDate: helper.getValue(items, 'requestDate'),
				npwp: helper.getValue(items, 'npwp'),
				ktpImage: helper.getValue(items, 'ktpImage'),
				selfieImage: helper.getValue(items, 'selfieImage')
			}

			let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == detailItems.mid)
			detailItems.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : detailItems.mid

			return detailItems

		},
		detailLabel: state => state.detail.label,
		detailOptions: state => state.detail.options,
		applicationform: (state, getters, rootState) => {
			let form = {
				adminPhoneNumber: rootState.auth.user.detail.adminPhoneNumber,
				adminPassword: rootState.auth.user.detail.adminPin,
				userPhoneNumber: state.detail.items.phoneNumber,
				userMid: state.detail.items.mid,
			}
			return form
		}
	},
	actions: {
		async fetchData({getters, commit}) {
			try {
				const response = await DefaultService.getListKYC(getters.dataForm)
				commit('setData', response.data.result.rows)
				return response.data.result.totalCount
			} catch (error) {
				commit('setData', null)
				return error
			}
		},
		async exportData({commit, getters}) {
			try {
				const response = await DefaultService.exportKYC(getters.dataForm)
				return response.data
			} catch (error) {
				console.log(error)
				commit('Popup/updateErrors', error, { root: true })
			}
		},
		async fetchDetail({commit,rootState}, payload) {
			try {
				commit('Preload/resetstate', undefined,{ root: true })
				let decryptedID = helper.decrypt(payload, true)
				if(decryptedID) {
					let requestPayload = {
						adminPhoneNumber: rootState.auth.user.detail.adminPhoneNumber,
						adminPassword: rootState.auth.user.detail.adminPin,
						userPhoneNumber: decryptedID
					}
					const response = await DefaultService.getDetailKYC(requestPayload)

					commit('setDetail', response.data.result)
					commit('Preload/updatestate', response.data.result, { root: true })
					return response.data.result 
				} else {
					commit('Preload/updatestate', null,{ root: true })
				}
			} catch (error) {
				commit('Preload/updatestate', undefined, { root: true })
			}
		},
		async acceptKYC({getters, commit}) {
			try {
				commit('updateLoading', true)
				await DefaultService.acceptKYC(getters.applicationform)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				console.log(error)
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
		async declineKYC({getters, commit}, payload) {
			try {
				commit('updateLoading', true)
				let applicationform = getters.applicationform
				let rejectReason = payload.reason == 'Other' ? payload.reason_text : payload.reason
				applicationform['Description'] = rejectReason
				commit('setRejectReason', rejectReason)
				await DefaultService.declineKYC(applicationform)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				console.log(error)
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
	},
  mutations: {
		setData(state, data) {
			state.data.items = data
		},
		setDetail(state, data) {
			state.detail.items = data
		},
		updateLoading(state, data) {
			state.detail.loading = data
		},
		setRejectReason(state, data) {
			state.detail.label.popup.rejection.submitSubtitle = 'Reason for declining : ' + data
		}
	}
};