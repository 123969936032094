<template>
  <div id="app">
    <AppLayout>
      <router-view :key="$route.fullPath" />
    </AppLayout> 
  </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
export default {
  name: 'App',
  components: { AppLayout }
}
</script>
