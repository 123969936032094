import _connection from '@/services/_connection'

const RESOURCE_NAME = 'deposit'

export default {
	// BANK
	getListBank(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/bank', params)
	},

	// DASHBOARD
	getBalance(param) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/balance/' + param)
	},
	getTotal(param, query) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/total/' + param, query)
	},

	// MERCHANT CASHOUT
	getList(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/cash-out/history/internal', params)
	},
	exportList(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/cash-out/csv/admin', params)
	},
	getDetail(id) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/cash-out/history/internal/' + id)
	},
	RejectMC(id, payload) {
		return _connection.postRequest(RESOURCE_NAME, 'v2/cash-out/flagging/' + id, payload)
	},
	
	// MANUAL TRANSFER
	getManualTransfer(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/cashout/history', params)
	},
	getManualTransferByID(id) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/cashout/history/' + id)
	},
	submitManualTransfer(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'v2/admin-dashboard/cashout/manual', payload)
	},

	// ADMIN FEE
	getAdminFee(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/fee', params)
	},
	newAdminFee(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'v2/admin-dashboard/fee', payload)
	},
	updateAdminFee(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'v2/admin-dashboard/fee/update', payload)
	},

	// MANAGE PARTNER
	getPartner(params) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner', params)
	},
	getPartnerDetail(id) {
		return _connection.getRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner/' + id)
	},
	newPartnerBank(payload) {
		return _connection.postRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner/bank', payload)
	},
	updateFlowTypePartner(payload) {
		return _connection.putRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner/flow', payload)
	},
	updateSftpPartner(payload) {
		return _connection.putRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner/sftp', payload)
	},
	updateDiscountPartner(payload) {
		return _connection.putRequest(RESOURCE_NAME, 'v2/admin-dashboard/partner/discount', payload)
	}
}
