import Axios from 'axios'

// URI 
const baseURL = {
	'default' : process.env.VUE_APP_URL_DEFAULT,
	'deposit' : process.env.VUE_APP_URL_DEPOSIT,
	'dummy' : process.env.VUE_APP_URL_DUMMY,
}

let getHeaders = () => {
	const timestamp = new Date().getTime().toString().padEnd(16, '0')
	const token = localStorage.getItem("accessToken")
	return {
		basicToken: 'Bearer ' + token,
		timestamp: timestamp
	}
}

// STATIC HEADERS
// const getHeaders = () => {
// 	const CryptoJS = require("crypto-js");
// 	const Buffer = require('buffer/').Buffer

// 	const timestamp = new Date().getTime().toString().padEnd(16, '0')
// 	const keyPromiseCbc = '9F%J@NcRfUjXn2r5u8x/A?D#GyKaPdSg';

// 	const AESCBCEncryptWithKey = (secretKey, iv, txt = '') => {
// 		const cipher = CryptoJS.AES.encrypt(txt, CryptoJS.enc.Utf8.parse(secretKey), {
// 			iv: CryptoJS.enc.Utf8.parse(iv),
// 			mode: CryptoJS.mode.CBC,
// 		});

// 			return cipher.toString();
// 	};

// 	const user = 'dev';
// 	const pass = 'supersecretpassword';
// 	const encryptUser = AESCBCEncryptWithKey(keyPromiseCbc, timestamp, user);
// 	const encryptPass = AESCBCEncryptWithKey(keyPromiseCbc, timestamp, pass);

// 	const basicToken = Buffer.from(`${encryptUser}:${encryptPass}`).toString('base64');

// 	return {
// 		basicToken: 'Basic ' + basicToken,
// 		timestamp: timestamp
// 	}
// }

const getConfigHeaders = () => {
	const headers = getHeaders()
	return { 
		'headers': 
		{ 
			'Authorization': headers.basicToken, 
			'timestamp': headers.timestamp
		} 
	}
}

// UNCOMMENT IF USING STATIC HEADERS
// Axios.interceptors.request.use(config => {
// 	const refreshHeaders = getHeaders()
// 	config.headers['Authorization'] = refreshHeaders.basicToken
// 	config.headers['timestamp'] = refreshHeaders.timestamp

// 	return config;
// }, function (error) {
// 	return Promise.reject(error);
// });

Axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	if (error.response && error.response.data && error.response.data.status === 400) {
			return Promise.reject(error.response.data);
	}
	return Promise.reject(error.message);
});

export default {
	getRequest(base_name, uri_path, params=null) {
		let config = getConfigHeaders()
		if(params) config.params = params

		return Axios.get(baseURL[base_name] + uri_path, config)
	},
	postRequest(base_name, uri_path, payload) {
		return Axios.post(baseURL[base_name] + uri_path, payload, getConfigHeaders())
	},
	putRequest(base_name, uri_path, payload) {
		return Axios.put(baseURL[base_name] + uri_path, payload, getConfigHeaders())
	}
}