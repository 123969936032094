
import { createRouter, createWebHistory } from 'vue-router'
import KycRoutes from '@/router/KycRoutes'
import MerchantsCashoutRoutes from '@/router/MerchantsCashoutRoutes'
import PartnerRoutes from '@/router/PartnerRoutes'
import NotFoundView from '@/views/pages/NotFound.vue'


const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/DashboardView.vue'),
    meta: {
      title: 'Dashboard',
      icon: 'nav-icon-dashboard',
      hasSubMenu: false,
      breadcrumb: [
        { name: 'Home', link: '/'},
        { name: 'Dashboard'}
      ]
    }
  },
  {
    path: '/kyc',
    name: 'Kyc',
    component: () => import('@/views/kyc/KycView.vue'),
    children: KycRoutes,
    meta: {
      title: 'KYC',
      icon: 'nav-icon-kyc',
      breadcrumb: [
        { name: 'Home', link: '/'},
        { name: 'KYC'}
      ],
      menu: [
        { name: 'application', link: '/kyc'},
        { name: 'declined', link: '/kyc/declined'},
        { name: 'accepted', link: '/kyc/accepted'}
      ]
    }
  },
  {
    path: '/merchants-cashout',
    name: 'merchantscashout',
    component: () => import('@/views/merchantsCashout/MerchantsCashoutView.vue'),
    children: MerchantsCashoutRoutes,
    meta: {
      title: 'Merchants Cashout',
      icon: 'nav-icon-merchant-cashout',
      hasSubmenu: true,
      breadcrumb: [
        { name: 'Home', link: '/'},
        { name: 'Merchants Cashout'}
      ]
    }
  },
  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner/PartnerView.vue'),
    children: PartnerRoutes,
    meta: {
      title: 'Partner',
      icon: 'nav-icon-partner',
      hasSubmenu: true,
      breadcrumb: [
        { name: 'Home', link: '/'},
        { name: 'Partner'}
      ]
    }
  },
  // 404 Not Found
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFoundView,
    meta: {
      hidden: true,
      hasSubmenu: false
    }
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
