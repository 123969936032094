export default [
	{
		path: '',
		name: 'KYC LIST',
		component: () => import('@/views/kyc/KycList.vue'),
		meta: {
			title: 'KYC',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'KYC'}
			]
		}
	},
	{
		path: 'detail/:id',
		component: () => import('@/views/kyc/KycDetail.vue'),
		meta: {
			title: 'KYC',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'KYC', link: '/kyc'},
				{ name: 'Detail'}
			]
		}
	}
]