import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import AppLayout from './layouts/AppLayout'
import store from './store'
import Keycloak from 'keycloak-js'
// import VueLogger from 'vuejs3-logger';
import optionsHelper from '@/plugins/options'
import { plugin, defaultConfig } from '@formkit/vue'

Axios.defaults.baseURL = process.env.VUE_APP_URL_CORE

const keycloak = Keycloak(optionsHelper.keycloak())

keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(async (auth) => {
  if (!auth) {
    window.location.reload()
  } else {
		// console.log('Authenticated')

		createApp(App)
			.use(router)
			.use(store)
			.provide('keycloak', keycloak)
			.component('AppLayout', AppLayout)
			.use(plugin, defaultConfig)
			.mount('#app')
		
		let payload = {
			idToken: keycloak.idToken,
			accessToken: keycloak.token
		}
		localStorage.setItem("accessToken", keycloak.token);

		if ((keycloak.token && keycloak.idToken) != '' && (keycloak.idToken != '')) {
			store.commit("auth/login", payload);

			// console.log("--> log: User has logged in: " + keycloak.subject);
			// console.log("--> log: TokenParsed: "+ JSON.stringify(keycloak.tokenParsed));
			// console.log("--> log: User name: " + keycloak.tokenParsed.preferred_username);
			// console.log("--> log: accessToken: " + payload.accessToken);
			// console.log("--> log: idToken: " + payload.idToken);
			// console.log("--> log: adminPhoneNumber: " + keycloak.tokenParsed.adminPhoneNumber)
			// console.log("--> log: adminPin: " + keycloak.tokenParsed.adminPin)
			// console.log("--> log: can_transfer_manual: " + keycloak.tokenParsed.can_transfer_manual)
			payload = {
				name: keycloak.tokenParsed.name,
				roles: Object.values(keycloak.tokenParsed.realm_access.roles),
				adminPhoneNumber: keycloak.tokenParsed.adminPhoneNumber,
				adminPin: keycloak.tokenParsed.adminPin,
				can_transfer_manual: keycloak.tokenParsed.can_transfer_manual
			};

			store.commit("auth/setDetail", payload);
		}
		else {
			let payloadRefreshedTokens = {
				idToken: "",
				accessToken: ""
			}
			localStorage.setItem("accessToken", "");
			store.commit("auth/login", payloadRefreshedTokens);
			store.commit("auth/logout");
		}
  }

	// Token Refresh
	setInterval(() => {
		keycloak.updateToken().then( async (refreshed) => {
			if (store.state.auth.user.isAuthenticated != false ) {
        if (refreshed) {
          // console.log("--> log: refreshed ");         
          let payloadRefreshedTokens = {
            idToken: keycloak.idToken,
            accessToken: keycloak.token
          }
					localStorage.setItem("accessToken", keycloak.token);

          if ((keycloak.token && keycloak.idToken != '') && (keycloak.idToken != '')) {
            store.commit("auth/login", payloadRefreshedTokens);
          }
          else {
            // console.log("--> log: token refresh problems");  
            payloadRefreshedTokens = {
              idToken: "",
              accessToken: ""
            }
						localStorage.setItem("accessToken", "");
            store.commit("auth/login", payloadRefreshedTokens);
            store.commit("auth/logout");
          }
        }
      } else {
        // console.log("--> log: logout isAuthenticated  =", store.state.user.isAuthenticated);
        
        var logoutOptions = { redirectUri : 'http://localhost:8080/' };
        // console.log("--> log: logoutOptions  ", logoutOptions  );
            
        keycloak.logout(logoutOptions).then((success) => {
              console.log("--> log: logout success ", success );
        }).catch((error) => {
              console.log("--> log: logout error ", error );
        });
        store.commit("auth/logout");
      }
		}).catch(() => {
			console.log('Failed to refresh token')
		})
	}, 6000)
}).catch(() => {
	console.log('Authenticated Failed')
})
