export default {
	keycloak() {
		return {
			realm: process.env.VUE_APP_KEYCLOAK_REALM,
			clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
			url: process.env.VUE_APP_KEYCLOAK_API
		}
	},
	logger() {
		return {
			isEnabled: true,
			logLevel : process.env.NODE_ENV ? 'error' : 'debug',
			stringifyArguments : false,
			showLogLevel : true,
			showMethodName : true,
			separator: '|',
			showConsoleColors: true
		}
	}
}