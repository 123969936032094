<template>
	<router-link to="/" class="sidebar-brand d-flex align-items-center justify-content-center">
		<img alt="Zipay logo" src="../../assets/img/logo-sm.svg">
	</router-link>
</template>

<script>
	export default {
		name: 'SidebarBrandComponent'
	}
</script>
