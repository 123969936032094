import moment from 'moment'
import DepositService from '@/services/deposit.service';


export const manualTransfer = {
  namespaced: true,
	state: {
		form: {
			loading: false,
			items: {},
			label: {
				main: [
					{label: "Bank Account No", name: "destinationAccountNo", type: 'number', validation:'required|number'},
					{label: "Bank Name", name: "bankId", type: 'select', placeholder: 'Select a bank', validation:"required"},
					{label: "Merchant Phone Number", name: "merchantPhoneNumber", type: 'number', validation:'required|number'}, 
					{label: "Nominal Transfer", name: "amount", type: 'number', validation:'required|number', attr: {currency: true}},  
					{label: "Merchant", name: "mid", type: 'select', placeholder: 'Select a merchant', validation:'required'}, 
					{label: "Tanggal Transfer", name: "date", type: 'datetime-local', validation:"required"}, 
					{label: "Upload Bukti Transfer", name: "file", type: 'file', accept: '.jpg,.jpeg,.gif,.png', validation:"required"} 
				],
				popup: {
					confirmDataTitle: 'Manual Transfer',
					submitTitle: 'Manual Transfer Request Submitted',
					submitSubtitle: 'Manual Transfer Request successfully submitted!'
				}
			}
		},
		data: {
			items: [],
			label: {
				table: [
					{name: 'referenceCode', label: 'REF ID', isLinked: true},
					{name: 'Partner.merchant_name', label: 'Merchant'},
					{name: 'date', label: 'Transfer Date'},
					{name: 'amount', label: 'Total Transfer Amount', attr: {currency: true}},
					{name: 'BankMaster.name', label: 'Bank'},
					{name: 'BankMaster.gatewayName', label: 'Gateway'},
					{name: 'bankAccountNo', label: 'Bank Account No.'},
					{name: 'bankAccountHolderName', label: 'Bank Account Name'}
				],
				filter: [
					{label: "From", name: "startDate", type: 'date', attr: {suffix: '~'}},
					{label: "To", name: "endDate", type: 'date'},
					{label: "Merchant", name: "mid", type: 'select', placeholder: 'Merchant'},
					{label: "Gateway", name: "gatewayName", type: 'text', placeholder: 'Gateway'}
				]
			}
		},
		detail: {
			label: {
				info: [
					{name: 'referenceCode', label: 'REF ID'},
					{name: 'Partner.merchant_name', label: 'Merchant'},
					{name: 'date', label: 'Transfer Date'},
					{name: 'amount', label: 'Total Transfer Amount', attr: {currency: true}},
					{name: 'BankMaster.name', label: 'Bank'},
					{name: 'bankAccountNo', label: 'Bank Account No.'},
					{name: 'bankAccountHolderName', label: 'Bank Account Name'}
				],
				image: [
					{name: 'evidenceOfTransferImage', label: 'Evidence of Transfer', type: 'base64'}
				]
			}
		}
	},
	getters: {
		formLoading: state => state.form.loading,
		formItems: (state, getters, rootState) => {
			let formItems = { ...state.form.items}

			// bank value
			if(formItems.bankId) {
				let selectedBank = rootState.BankMaster.items.filter(bank => bank.value == formItems.bankId)
				formItems.bankId_value = selectedBank[0].label
			}

			// merchant value
			if(formItems.mid) {
				let selectedMerchant = rootState.Merchants.items.filter(merchant => merchant.value == formItems.mid)
				formItems.mid_value = selectedMerchant[0].label
			}

			// datetime
			if(formItems.date) {
				formItems.date_value = moment(formItems.date).format('YYYY-MM-DD HH:mm:ss');
			}

			if(formItems.file) {
				formItems.file_value = formItems.file[0].name
			}

			return formItems
		},
		payload: (state, getters, rootState) => {
			let formData = state.form.items
			let formattedDate = moment(formData['date']).format('YYYY-MM-DD HH:mm:ss');

			let payload = new FormData()
			payload.append('destinationAccountNo', formData['destinationAccountNo'])
			payload.append('bankId', formData['bankId'])
			payload.append('adminPin', rootState.auth.user.detail.adminPin)
			payload.append('adminPhoneNumber', rootState.auth.user.detail.adminPhoneNumber)
			payload.append('merchantPhoneNumber', formData['merchantPhoneNumber'])
			payload.append('amount', formData['amount'])
			payload.append('mid', formData['mid'])
			payload.append('date', formattedDate)
			payload.append('file', formData['file'][0].file)

			return payload

		},
		formLabel: (state, getters, rootState) => {
			let formLabel = state.form.label
			formLabel.main = formLabel.main.map(main => {
				if(main.name == 'bankId') {
					main.options = rootState.BankMaster.items
				}
				if(main.name == 'mid') {
					main.options = rootState.Merchants.items
				}
				return main
			})
			return formLabel
		},
		dataItems: (state, getters, rootState) => {
			let dataItems = null
			let data = state.data.items

			if(data !== null) {
				let mutatedData = data.map(mutateData => {
					let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == mutateData.Partner.mid)
					mutateData.Partner.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : mutateData.Partner.mid

					return mutateData
				})
				dataItems = mutatedData
			}
			return dataItems
		},
		dataLabel: (state, getters, rootState) => {
			let dataLabel = state.data.label

			dataLabel.filter = dataLabel.filter.map(filter => {
				if(filter.name == 'mid'){
					filter.options = rootState.Merchants.items
				}

				return filter
			})

			return dataLabel
		},
		detailLabel: state => state.detail.label
	},
	actions: {
		async fetchData({rootState, commit}) {
			try {
				const response = await DepositService.getManualTransfer(rootState.tabledata.form.manualtransfer)
				commit('setData', response.data.data.rows)
				return response.data.data.count
			} catch (error) {
				console.log(error)
				commit('setData', null)
			}
		},
		async submitForm({getters, commit}) {
			try {
				commit('loading', true)
				await DepositService.submitManualTransfer(getters.payload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('loading', false)
			} catch (error) {	
				commit('Popup/updateErrors', error, { root: true })
				commit('loading', false)
			}
		},
		async fetchDetail({commit, rootState}, payload) {
			try {
				commit('Preload/resetstate', undefined,{ root: true })
				const response = await DepositService.getManualTransferByID(payload)
				let response_data = response.data.data

				let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == response_data.Partner.mid)

				response_data.Partner.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : response_data.Partner.mid

				commit('Preload/updatestate', response_data, { root: true })
				return response_data
			} catch (error) {
				console.log(error)
				commit('Preload/updatestate', undefined, { root: true })
			}
		}
	},
	mutations: {
		setFormItems(state, data) {
			state.form.items = data
		},
		setData(state, data) {
			state.data.items = data
		},
		loading(state, data) {
			state.form.loading = data
		}
	}
};

export const merchantCashout = {
	namespaced: true,
	state: {
		data: {
			items: [],
			label: {
				table: [
					{name: 'referenceId', label: 'REF ID', isLinked: true},
					{name: 'merchant_name', label: 'Merchant'},
					{name: 'createdAt', label: 'Request Date'},
					{name: 'bankAccountNo', label: 'Bank Account Number'},
					{name: 'BankMaster.name', label: 'Bank Name'},
					{name: 'bankAccountHolderName', label: 'Account Name'},		
					{name: 'deductedAmount', label: 'Deducted Amount', attr: {currency: true}},		
					{name: 'trxStatus', label: 'Status'}		
				],
				filter: [
					{label: "From", name: "startDate", type: 'date', attr: {suffix: '~'}},
					{label: "To", name: "endDate", type: 'date'},
					{label: "Merchant ID", name: "mid", type: 'select', placeholder: 'Merchant'},
					{label: "Search By", name: "searchby", type: 'select', attr: {onchange: true}},
					{label: "Status", name: "status", type: 'select', placeholder: 'Select Status', attr: {depended: true }},
					{label: "REF ID", name: "refID", type: 'text', placeholder: 'REF ID', attr: {depended: true }},
					{label: "Bank Account Number", name: "bankAccountNo", type: 'text', placeholder: 'Bank Account Number', attr: {depended: true }}
				],
			},
			options: {
				search: [
					{ label: 'Search Term', value: '' },
					{ label: 'Status', value: 'status' },
					{ label: 'REF ID', value: 'refID' },
					{ label: 'Bank Account Number', value: 'bankAccountNo' }
				],
				status: [
					{ label: 'All Status', value: '' },
					{ label: 'Pending', value: 'PENDING' },
					{ label: 'Success', value: 'SUCCESS' },
					{ label: 'Failed', value: 'FAILED' }
				]
			}
		},
		detail: {
			loading: false,
			items: {},
			label: {
				form: [
					{ name: 'failureReason', label: 'Reject Reason' },
					{ name: 'trxStatus', label: 'Status' },
				],
				merchant: [
					{ name: 'merchant_name', label: 'Merchant' }, 
					{ name: 'msisdn', label: 'Phone Number' },
					{ name: 'trxStatus', label: 'Status' },
				],
				refund: [
					{name: 'transferFee', label: 'Transfer Fee', attr: {currency:true}},
					{name: 'adminFee', label: 'Admin Fee', attr: {currency:true}},
					{name: 'totalAmount', label: 'Total Amount', attr: {currency:true}},
					{name: 'transferedAmount', label: 'Transfered Amount', attr: {currency:true}},
					{name: 'deductedAmount', label: 'Deducted Amount', attr: {currency:true}},
					{name: 'profitAmount', label: 'Profit Amount', attr: {currency:true}},
					{name: 'lossAmount', label: 'Loss Amount', attr: {currency:true}},
					{name: 'referenceId', label: 'Ref ID'},
					{name: 'description', label: 'Description'},
					{name: 'createdAt', label: 'Request Date'},
					{name: 'trxStatus', label: 'Status', stylename: 'status_style'},
					{name: 'updatedAt', label: 'Update Date'},
					{name: 'failureReason', label: 'Failure Reason', attr: {defaultValue:'-'}},
					{name: 'errorMessage', label: 'Error Message', attr: {defaultValue:'-'}},
				],
				bank: [
					{name: 'BankMaster.name', label: 'Bank Name'},
					{name: 'BankMaster.gatewayName', label: 'Gateway Name'},
					{name: 'bankAccountNo', label: 'Nomor Rekening'},
					{name: 'bankAccountHolderName', label: 'Nama Pemilik'}
				],
				popup: {
					formTitle: "Reject Request Merchant Cashout",
					submitTitle: "Request Merchant Cashout Rejected" ,
					submitSubtitle: "Reason for Rejecting : "
				}
			}
		}
	},
	getters: {
		dataItems: (state, getters, rootState) => {
			let dataItems = null
			let data = state.data.items
			
			if(data !== null) {
				let mutatedData = data.map(mutateData => {
					let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == mutateData.mid)
					mutateData.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : mutateData.mid

					return mutateData
				})
				dataItems = mutatedData
			}
			return dataItems
		},
		dataLabel: (state, getters, rootState) => {
			let dataLabel = state.data.label

			dataLabel.filter = dataLabel.filter.map(filter => {
				if(filter.name == 'mid'){
					filter.options = rootState.Merchants.items
				}
				
				if(filter.name == 'searchby'){
					filter.options = state.data.options.search
				}

				if(filter.name == 'status'){
					filter.options = state.data.options.status
				}

				return filter
			})

			return dataLabel
		},
		detailLoading: state => state.detail.loading,
		detailLabel: state => state.detail.label,
		detailItems: (state, getters, rootState) => {
			let data = state.detail.items

			if(data !== null) {
				let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == data.mid)
				data.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : data.mid
			}
			return data
		},
		
	},
	actions: {
		async fetchData({commit, rootState}) {
			try {
				const response = await DepositService.getList(rootState.tabledata.form.merchant)
				commit('setData', response.data.data.rows)
				return response.data.data.count
			} catch (error) {
				commit('setData', null)
				console.log(error)
			}
		},
		async exportData({rootState, commit}) {
			try {
				rootState.tabledata.form.merchant.page = '1'
				rootState.tabledata.form.merchant.limit = rootState.tabledata.attr.totalRequest
				const response = await DepositService.exportList(rootState.tabledata.form.merchant)
				return response.data.data.data
			} catch (error) {
				console.log(error)
				commit('Popup/updateErrors', error, { root: true })
			}
		},
		async fetchDetail({commit}, payload) {
			try {
				commit('Preload/resetstate', undefined,{ root: true })
				const response = await DepositService.getDetail(payload)
				let response_data = response.data.data
				if(response_data !== null) {
					// status colors
					if(response_data.trxStatus == 'SUCCESS') {
						response_data.status_style = 'colored-label-success'
					} else if(response_data.trxStatus == 'FAILED') {
						response_data.status_style = 'colored-label-danger'
					} else {
						response_data.status_style = 'colored-label-warning'
					}
					commit('setDetailData', response_data)
				}
				commit('Preload/updatestate', response_data, { root: true })
				return response_data
			} catch (error) {
				console.log(error)
				commit('Preload/updatestate', undefined, { root: true })
			}
		},
		async RejectRequest({state, commit}, payload) {
			try {
				commit('updateLoading', true)
				let id = state.detail.items.id
				let postPayload = {
					failureReason: payload.failureReason,
					trxStatus: 'FAILED'
				}

				commit('setRejectReason', payload.failureReason)
				await DepositService.RejectMC(id, postPayload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				console.log(error)
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
	},
	mutations: {
		setData(state, data) {
			state.data.items = data
		},
		setDetailData(state, data) {
			state.detail.items = data
		},
		updateLoading(state, data) {
			state.detail.loading = data
		},
		setRejectReason(state, data) {
			state.detail.label.popup.submitSubtitle = 'Reason for declining : ' + data
		}
	}
}
