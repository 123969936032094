<template>
  <div id="wrapper">
    <MainSidebarLayout />
    <div id="content-wrapper">
        <div id="content">
            <MainTopbarLayout />
            <div class="container-fluid">
              <slot />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import MainSidebarLayout from '@/layouts/SidebarLayout.vue'
import MainTopbarLayout from '@/layouts/TopbarLayout.vue'

export default {
  name: 'AppLayout',
  components: {
    MainSidebarLayout,
    MainTopbarLayout
  }
}
</script>

<style lang="scss">
    @import "@/assets/style/main.scss";
</style>
