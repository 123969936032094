import DepositService from '@/services/deposit.service';

export const dashboard = {
  namespaced: true,
  state: {
		loading: {
			BalanceXfers: false,
			BalanceGv: false,
			TotalProfitAmount: false,
			TotalLossAmount: false,
			TotalDeductedAmount: false,
			TotalTransferedAmount: false,
		},
		data: {
			cards: {
				BalanceXfers: {},
				BalanceGv: 0,
				TotalProfitAmount: 0,
				TotalLossAmount: 0,
				TotalDeductedAmount: 0,
				TotalTransferedAmount: 0,
			},
			trxStatus: ''
		},
		labels: {
			filter: [{
				name: 'trxStatus', 
				label: 'Status', 
				placeholder: 'Status', 
				type: 'select', 
				options: [
					{label: 'All Status', value: ''}, 
					{label: 'Pending', value: 'PENDING'},
					{label: 'Success', value: 'SUCCESS'},
					{label: 'Failed', value: 'FAILED'}
				]
			}],
			cards: {
				data: [
					{data: 'BalanceXfers', name: 'totalBalance', label: 'Balance XFERS: Total Balance', attr: {currency: true}},
					{data: 'BalanceXfers', name: 'availableBalance', label: 'Balance XFERS: Available Balance', attr: {currency: true}},
					{data: 'BalanceXfers', name: 'pendingBalance', label: 'Balance XFERS: Pending Balance', attr: {currency: true}},
					{data: 'BalanceGv', name: 'saldo', label: 'Balance GV', attr: {currency: true}},
					{data: 'TotalProfitAmount', name: 'profitAmount', label: 'Total Profit Amount', attr: {currency: true}},
					{data: 'TotalLossAmount', name: 'lossAmount', label: 'Total Loss Amount', attr: {currency: true}},
					{data: 'TotalDeductedAmount', name: 'deductedAmount', label: 'Total Deducted Amount', attr: {currency: true}},
					{data: 'TotalTransferedAmount', name: 'transferedAmount', label: 'Total Transfered Amount', attr: {currency: true}}
				]
			}
		}
	},
  getters: {
		loading: state => state.loading,
		data: state => state.data,
		labels: state => state.labels
	},
	actions: {
		async fetchBalanceXfers({commit}) {
			try {
				commit('updateLoader', {name: 'BalanceXfers', value: true})
				const response = await DepositService.getBalance('xfers')
				commit('setCards', {name: 'BalanceXfers', value: response.data.data})
				commit('updateLoader', {name: 'BalanceXfers', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'BalanceXfers', value: 'Unknown Error'})
				commit('updateLoader', {name: 'BalanceXfers', value: false})
			}
		},
		async fetchBalanceGv({commit}) {
			try {
				commit('updateLoader', {name: 'BalanceGv', value: true})
				const response = await DepositService.getBalance('gv')
				commit('setCards', {name: 'BalanceGv', value: response.data.data})
				commit('updateLoader', {name: 'BalanceGv', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'BalanceGv', value: 'Unknown Error'})
				commit('updateLoader', {name: 'BalanceGv', value: false})
			}
		},
		async fetchTotalProfit({state, commit}) {
			try {
				commit('updateLoader', {name: 'TotalProfitAmount', value: true})
				const response = await DepositService.getTotal('profit', state.data.trxStatus)
				commit('setCards', {name: 'TotalProfitAmount', value: response.data.data})
				commit('updateLoader', {name: 'TotalProfitAmount', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'TotalProfitAmount', value: 'Unknown Error'})
				commit('updateLoader', {name: 'TotalProfitAmount', value: false})
			}
		},
		async fetchTotalLoss({state, commit}) {
			try {
				commit('updateLoader', {name: 'TotalLossAmount', value: true})
				const response = await DepositService.getTotal('loss', state.data.trxStatus)
				commit('setCards', {name: 'TotalLossAmount', value: response.data.data})
				commit('updateLoader', {name: 'TotalLossAmount', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'TotalLossAmount', value: 'Unknown Error'})
				commit('updateLoader', {name: 'TotalLossAmount', value: false})
			}
		},
		async fetchTotalDeducted({state, commit}) {
			try {
				commit('updateLoader', {name: 'TotalDeductedAmount', value: true})
				const response = await DepositService.getTotal('deducted', state.data.trxStatus)
				commit('setCards', {name: 'TotalDeductedAmount', value: response.data.data})
				commit('updateLoader', {name: 'TotalDeductedAmount', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'TotalDeductedAmount', value: 'Unknown Error'})
				commit('updateLoader', {name: 'TotalDeductedAmount', value: false})
			}
		},
		async fetchTotalTransfered({state, commit}) {
			try {
				commit('updateLoader', {name: 'TotalTransferedAmount', value: true})
				const response = await DepositService.getTotal('transfered', state.data.trxStatus)
				commit('setCards', {name: 'TotalTransferedAmount', value: response.data.data})
				commit('updateLoader', {name: 'TotalTransferedAmount', value: false})
			} catch (error) {
				console.log(error)
				commit('setCards', {name: 'TotalTransferedAmount', value: 'Unknown Error'})
				commit('updateLoader', {name: 'TotalTransferedAmount', value: false})
			}
		}
	},
	mutations: {
		setCards(state, data) {
			state.data.cards[data.name] = data.value
		},
		updateLoader(state, data) {
			state.loading[data.name] = data.value
		},
		setTrxStatus(state, data) {
			state.data.trxStatus = data
		}
	}
};
