<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
		<ul class="navbar-nav ml-auto">
			<li class="nav-item dropdown no-arrow">
				<a @click="profileToggle" class="nav-link dropdown-toggle" href="#" id="userDropdown">
						<img class="img-profile rounded-square" src="@/assets/img/profile.svg">
						<span class="mr-2 d-none d-lg-inline text-header small">{{this.$store.state.auth.user.name}}</span>
						<i class="arrow-header" :class="!profileTrigger ? 'down' : 'up'"></i>
				</a>
				<div v-if="profileTrigger" class="dropdown-menu dropdown-menu-right">
						<!-- <a class="dropdown-item" href="#">
								<i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
								Profile
						</a>
						<div class="dropdown-divider"></div> -->
						<div @click="logout" class="dropdown-item" href="#">
								<i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
								<a :href="logoutURI"> Logout </a>
						</div>
				</div>
			</li>
		</ul>
	</nav>
</template>

<script>
import { inject } from 'vue'

export default {
	name: 'TopbarLayout',
	data () {
		return {
			profileTrigger: false,
			keycloak: inject('keycloak'),
			logoutURI: process.env.VUE_APP_KEYCLOAK_API + '/realms/zipay/protocol/openid-connect/logout'
		}
	},
	methods: {
		profileToggle() {
			this.profileTrigger = !this.profileTrigger 
		}
	}
}
</script>
