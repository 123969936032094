import { get } from 'lodash'
import helper from '@/plugins/helper'
import DepositService from '@/services/deposit.service';
import DefaultService from '@/services/default.service';

export const BankMaster = {
	namespaced: true,
	state: {
		items: [],
		form: {
			sort: 'ASC',
			limit: 1000,
			page: 1,
			searchTerm: ''
		},
		value: ''
	},
	actions: {
		async fetchData({state, commit}, payload) {
			try {
				const response = await DepositService.getListBank(state.form)
				commit('setValue', payload)
				commit('setData', response.data.data.rows)
			} catch (error) {
				console.log(error)
			}
		},
	},
	mutations: {
		setValue(state, name) {
			state.value = name || 'idBank'
		},
		setData(state, banks) {
			let mutateBank = banks.map(bank => {
				return {
					label: get(bank, 'name', ''),
					value: get(bank, state.value, '')
				}
			})
			state.items = mutateBank
		}
	}
}

export const Merchants = {
	namespaced: true,
	state: {
		items: []
	},
	getters: {
		formdata: (state, getters, rootState) => {
			let form = {
				adminPhoneNumber: rootState.auth.user.detail.adminPhoneNumber,
				adminPassword: rootState.auth.user.detail.adminPin,
				pageNumber: 1,
				pageSize: 1000
			}
			return form
		}
	},
	actions: {
		async fetchData({getters, commit}) {
			try {
				const response = await DefaultService.getMerchants(getters.formdata)
				commit('setData', response.data.result.rows)
			} catch (error) {
				console.log(error)
			}
		},
	},
	mutations: {
		setData(state, data) {
			let mutateData = data.map(d => {
				return {
					label: get(d, 'name', ''),
					value: get(d, 'mid', '')
				}
			})
			state.items = mutateData
		}
	}
}

export const Popup = { 
	namespaced: true,
	state: {
		triggers: {},
		loading: false,
		errors: {}
	},
	getters: {
		triggers: state => state.triggers,
		errors: state => state.errors,
	},
	mutations: {
		initSubmitGroup(state) {
			state.triggers = {
				confirmdata: false,
				submitted: false
			},
			state.errors = {
				status: false,
				title: '',
				subtitle: '',
				data: null
			}
		},
		initFormGroup(state) {
			state.triggers = {
				confirmdata: false,
				submitted: false
			},
			state.errors = {
				status: false,
				title: '',
				subtitle: '',
				data: null
			}
		},
		updateTrigger(state, name) {
			state.triggers[name] = !state.triggers[name]
		},
		updateErrors(state, data) {
			if(data.success == false) {
				state.errors = helper.errorValidation(data)
			} else {
				state.errors = helper.errorUnknown()
			}
		},
		setErrorStatus(state, data) {
			state.errors.status = data
		}
	}
}

export const Preload = {
	namespaced: true,
	state: {
		loading: true,
		notfound: false,
		error: true
	},
	getters: {
		loading: state => state.loading,
		notfound: state => state.notfound,
		error: state => state.error
	},
	mutations: {
		resetstate(state) {
			state.loading = true
			state.notfound = false
			state.error = false
		},
		updatestate(state, data) {
			if(data === undefined) {
				state.error = true
			} else if(data === null) {
				state.notfound = true
			}
			state.loading = false
		}
	}
}
