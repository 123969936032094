export const auth = {
  namespaced: true,
  state: {
		user: {
			isAuthenticated: false,
      name: "",
			roles: [],
      idToken: "",
      accessToken: "",
      detail: {}
		}
	},
  mutations: {
    logout(state) {
      state.user.isAuthenticated = false;
      state.user.name = "";
			state.user.roles = [],
      state.user.idToken = "";
      state.user.accessToken = "";
      state.user.detail = {};
    },
    login(state, payload) {
      state.user.isAuthenticated = true;
      state.user.idToken = payload.idToken;
      state.user.accessToken = payload.accessToken;
    },
    setDetail(state, payload) {
      state.user.name = payload.name;
			state.user.roles = payload.roles;
      state.user.detail.adminPhoneNumber = payload.adminPhoneNumber || ''
      state.user.detail.adminPin = payload.adminPin || ''
      state.user.detail.can_transfer_manual = payload.can_transfer_manual || false
    }
  }
};