import DepositService from '@/services/deposit.service';
import helper from '@/plugins/helper'

export const ManagePartner = { 
	namespaced: true,
	state: {
		data: {
			items: [],
			label: {
				table: [
					{name:'merchant_name', label:'Merchant'},
					{name:'flowType', label:'Flow Type'},
					{name:'id', label:'Action', linklabel: 'View', isLinked: true}
				]
			}
		},
		detail: {
			items: {
				partnerInfo: {},
				adminfee: [],
				adminfeeOptions: [],
				adminfeeInfo: {}
			},
			loading: {
				new: false,
				update: false
			},
			label: {
				partnerInfo: [
					{name:'mid', label:'Merchant ID', type: 'hidden', disabled: true},
					{name:'merchant_name', label:'Merchant', disabled: true},
					{name:'merchantToken', label:'Merchant Token', disabled: true},
					{name:'flowType', label:'Flow Type', type: 'number', validation:'required|number'}
				],
				sftpInfo: [
					{name:'activateSftpStatus', label:'Activate SFTP Status', type: 'select', placeholder: 'Select Status', validation:'required'},
					{name:'sftpHost', label:'SFTP Host', type: 'text', validation:'required'},
					{name:'sftpUsername', label:'SFTP Username', type: 'text', validation:'required'},
					{name:'sftpPassword', label:'SFTP Password', type: 'text', validation:'required'},
					{name:'sftpPort', label:'SFTP Port', type: 'number', validation:'required|number'},
					{name:'sftpInitiateRouteDir', label:'SFTP Initiate Route Directory', type: 'text', validation:'required'}
				],
				discountInfo: [
					{name:'promoStatusAdminFee', label:'Promo Status Admin Fee', type: 'select', placeholder: 'Select Status', validation:'required'},
					{name:'promoBalanceAdminFee', label:'Promo Balance Admin Fee', validation:'required|number', type: 'number', attr: {currency: true}}
				],
				table: [
					{name:'AdminFeeMaster.BankMaster.name', label:'Bank Name'},
					{name:'AdminFeeMaster.BankMaster.gatewayName', label:'Gateway Name'},
					{name:'AdminFeeMaster.fixFee', label:'Fix Fee', attr: {currency: true}},
					{name:'AdminFeeMaster.feePercentage', label:'Fee Percentage', attr: {percentage: true}},
					{name:'AdminFeeMaster.markupFromFeePercentage', label:'Markup From Fee Percentage', attr: {percentage: true}},
					{name:'AdminFeeMaster.minimalAmountCashout', label:'Minimal Amount Cashout', attr: {currency: true}},
					{name:'AdminFeeMaster.ppnPercentage', label:'PPN Percentage', attr: {percentage: true}},
					{name:'AdminFeeMaster.status', label:'Status'},
				],
				popup: {
					formTitle: 'New Bank Partner',
					submitTitle: 'New Bank Partner Submitted',
					submitSubtitle: 'New Bank Partner Successfully Submitted!'
				},
				popupInfo: {
					submitTitle: 'Update Partner Detail Submitted',
					submitSubtitle: 'Update Partner Detail Successfully Submitted!'
				},
				form: [
					{label: "Admin Fee", name: "adminFeeMasterId", type: 'select', placeholder: 'Select an Admin Fee', validation:'required', attr: {onchange: true}},
					{label: "Partner ID", name: "partnerId", type: 'hidden'}
				],
				adminfee: [
					{name:'BankMaster.name', label:'Bank Name'},
					{name:'BankMaster.gatewayName', label:'Bank Gateway'},
					{name:'fixFee', label:'Fix Fee', attr: {currency: true}},
					{name:'feePercentage', label:'Fee Percentage', attr: {percentage: true}},
					{name:'markupFromFeePercentage', label:'Markup From Fee Percentage', attr: {percentage: true}},
					{name:'minimalAmountCashout', label:'Minimal Amount Cashout', attr: {currency: true}},
					{name:'ppnPercentage', label:'PPN Percentage', attr: {percentage: true}},
					{name:'status', label:'Status'}
				]
			}
		}
	},
	getters: {
		dataItems: (state, getters, rootState) => {
			let dataItems = null
			let data = state.data.items
			if(data !== null) {
				let mutatedData = data.map(mutateData => {
					let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == mutateData.mid)
					mutateData.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : mutateData.mid

					return mutateData
				})
				dataItems = mutatedData
			}
			return dataItems
		},
		dataLabel: state => state.data.label,
		detailLoading: state => state.detail.loading,
		detailItems: state => state.detail.items,
		detailLabel: state => {
			let detailLabel = state.detail.label
			const StatusOptions = [
				{ label: 'Active', value: 'ACTIVE' },
				{ label: 'Non Active', value: 'NONACTIVE' }
			]

			// admin fee
			detailLabel.form = detailLabel.form.map(detailLabelForm => {
				if(detailLabelForm.name == 'adminFeeMasterId') {
					detailLabelForm.options = state.detail.items.adminfeeOptions
				}
				return detailLabelForm
			})

			// sftpInfo
			detailLabel.sftpInfo = detailLabel.sftpInfo.map(detailLabelSftpInfo => {
				if(detailLabelSftpInfo.name == 'activateSftpStatus') {
					detailLabelSftpInfo.options = StatusOptions
				}
				return detailLabelSftpInfo
			})
			
			// discountInfo
			detailLabel.discountInfo = detailLabel.discountInfo.map(detailLabelDiscountInfo => {
				if(detailLabelDiscountInfo.name == 'promoStatusAdminFee') {
					detailLabelDiscountInfo.options = StatusOptions
				}
				return detailLabelDiscountInfo
			})

			return detailLabel
		}
	},
	actions: {
		async fetchData({rootState, commit}) {
			try {
				const response = await DepositService.getPartner(rootState.tabledata.form.default)
				commit('setData', response.data.data.rows)
				return response.data.data.count
			} catch (error) {
				commit('setData', null)
				console.log(error)
			}
		},
		async fetchDetail({commit, rootState}, payload) {
			try {
				commit('Preload/resetstate', undefined,{ root: true })
				let decryptedID = helper.decrypt(payload, true)
				if(decryptedID) {
					const response = await DepositService.getPartnerDetail(decryptedID)
					let response_data = response.data.data

					let merchant_name = rootState.Merchants.items.filter(merchant => merchant.value == response_data.mid)
					response_data.merchant_name = merchant_name.length > 0 ? merchant_name[0].label : response_data.mid

					commit('setPartnerInfo', response_data)
					commit('Preload/updatestate', response_data, { root: true })
					return response_data
				} else {
					commit('Preload/updatestate', null,{ root: true })
				}
			} catch (error) {
				console.log(error)
				commit('Preload/updatestate', undefined,{ root: true })
			}
		},
		async fetchAdminFee({commit}) {
			try {
				const response = await DepositService.getAdminFee()
				commit('setAdminFee', response.data.data.rows)
			} catch (error) {
				console.log(error)
			}
		},
		async submitNewBankPartner({commit}, payload) {
			try {
				commit('newLoading', true)
				payload.partnerId = helper.decrypt(payload.partnerId, true)
				await DepositService.newPartnerBank(payload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('newLoading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('newLoading', false)
			}
		},
		async updatePartnerInfo({commit}, payload) {
			try {
				commit('updateLoading', true)
				let FlowtypePayload = {
					mid: payload.mid,
					flowType: payload.flowType
				}
				await DepositService.updateFlowTypePartner(FlowtypePayload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
		async updatePartnerSftp({commit}, payload) {
			try {
				commit('updateLoading', true)
				let SftpPayload = {
					mid: payload.mid,
					activateSftpStatus: payload.activateSftpStatus,
					sftpHost: payload.sftpHost,
					sftpUsername: payload.sftpUsername,
					sftpPassword: payload.sftpPassword,
					sftpPort: payload.sftpPort,
					sftpInitiateRouteDir: payload.sftpInitiateRouteDir
				}
				await DepositService.updateSftpPartner(SftpPayload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
		async updatePartnerDiscount({commit}, payload) {
			try {
				commit('updateLoading', true)
				let DiscountPayload = {
					mid: payload.mid,
					promoStatusAdminFee: payload.promoStatusAdminFee,
					promoBalanceAdminFee: payload.promoBalanceAdminFee
				}
				await DepositService.updateDiscountPartner(DiscountPayload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		}
	},
	mutations: {
		setData(state, data) {
			if(data !== null) {
				let mutatedData = data.map(mutateData => {
					mutateData.id = helper.encrypt(mutateData.id, true)
	
					return mutateData
				})
				state.data.items = mutatedData
			} else {
				state.data.items = null
			}
		},
		setPartnerInfo(state, data) {
			let bankpartners = data.ListAuthorizedBankPartners.map(bankpartner => {
				if(bankpartner.AdminFeeMaster.BankMaster === null) {
					bankpartner.AdminFeeMaster.BankMaster = {
						name: 'General Admin Fee'
					}
				}
				return bankpartner
			})

			state.detail.items.partnerInfo = {
				merchantToken: helper.getValue(data, 'merchantToken'),
				mid: helper.getValue(data, 'mid'),
				merchant_name: helper.getValue(data, 'merchant_name'),
				flowType: helper.getValue(data, 'flowType'),
				activateSftpStatus: helper.getValue(data, 'activateSftpStatus'),
				sftpHost: helper.getValue(data, 'sftpHost'),
				sftpUsername: helper.getValue(data, 'sftpUsername'),
				sftpPassword: helper.getValue(data, 'sftpPassword'),
				sftpPort: helper.getValue(data, 'sftpPort'),
				sftpInitiateRouteDir: helper.getValue(data, 'sftpInitiateRouteDir'),
				promoStatusAdminFee: helper.getValue(data, 'promoStatusAdminFee'),
				promoBalanceAdminFee: helper.getValue(data, 'promoBalanceAdminFee'),
				ListAuthorizedBankPartners: bankpartners
			}

			state.detail.items.adminfeeInfo = {}
		},
		setAdminFee(state, data) {
			let mutateData = data.map(dt => {
				return {
					label: helper.getValue(dt, 'BankMaster.name') + ' - Fix Fee: ' + helper.getValue(dt, 'fixFee', {currency: true}),
					value: helper.getValue(dt, 'id')
				}
			})
			state.detail.items.adminfee = data
			state.detail.items.adminfeeOptions = mutateData
		},
		getAdminfeeInfo(state, id=null) {
			state.detail.items.adminfeeInfo = []
			if(id) {
				let adminfee = state.detail.items.adminfee
				let selectedAdminfee = adminfee.filter(admfee => admfee.id == id)
				state.detail.items.adminfeeInfo = selectedAdminfee[0]
			}
		},
		newLoading(state, data) {
			state.detail.loading.new = data
		},
		updateLoading(state, data) {
			state.detail.loading.update = data
		}
	}
}

export const AdminFee = {
  namespaced: true,
	state: {
		data: {
			loading: {
				update: false
			},
			items: [],
			form: {
				viewdata: {},
				submitdata: {}
			},
			label: {
				table: [
					{name:'BankMaster.name', label:'Bank Name'},
					{name:'BankMaster.gatewayName', label:'Bank Gateway'},
					{name:'fixFee', label:'Fix Fee', attr: {currency: true}},
					{name:'feePercentage', label:'Fee Percentage', attr: {percentage: true}},
					{name:'markupFromFeePercentage', label:'Markup From Fee Percentage', attr: {percentage: true}},
					{name:'minimalAmountCashout', label:'Minimal Amount Cashout', attr: {currency: true}},
					{name:'ppnPercentage', label:'PPN Percentage', attr: {percentage: true}},
					{name:'status', label:'Status'},
					{name:'id', label:'Action', linklabel: 'View', isLinked: 'popup'}
				],
				form: [
					{label: "Fix Fee", name: "fixFee", type: 'number', validation:'required|number'},
					{label: "Bank Name", name: "bankMasterId", type: 'text', disabled: true}, 
					{label: "Minimal Amount Cashout", name: "minimalAmountCashout", type: 'number', validation:'required|number' },
					{label: "Fee Percentage", name: "feePercentage", type: 'number', validation:'number'},
					{label: "Markup From Fee Percentage", name: "markupFromFeePercentage", type: 'number', validation:'number'},
					{label: "PPN Percentage", name: "ppnPercentage", type: 'number', validation:'required|number'}
				],
				popup: {
					formTitle: 'Update Admin Fee',
					submitTitle: 'Update Admin Fee Submitted',
					submitSubtitle: 'Update Admin Fee Successfully Submitted!'
				}
			}
		},
		form: {
			loading: false,
			items: {},
			label: {
				default: [
					{label: "Fix Fee", name: "fixFee", type: 'number', validation:'required|number', attr:{currency: true}},
					{label: "Bank Name", name: "bankMasterId", type: 'select', placeholder: 'Select a bank', validation:"required"}, 
					{label: "Minimal Amount Cashout", name: "minimalAmountCashout", type: 'number', validation:'required|number', attr:{currency: true}},
					{label: "Fee Percentage", name: "feePercentage", type: 'number', validation:'number', attr:{percentage: true}},
					{label: "Markup From Fee Percentage", name: "markupFromFeePercentage", type: 'number', validation:'number', attr:{percentage: true}},
					{label: "PPN Percentage", name: "ppnPercentage", type: 'number', validation:'required|number', attr:{percentage: true}}
				],
				popup: {
					confirmDataTitle: 'New Admin Fee',
					submitTitle: 'New Admin Fee',
					submitSubtitle: 'New Admin Fee Successfully Submitted!'
				}
			}
		}
	},
	getters: {
		dataLoading: state => state.data.loading,
		dataItems: state => state.data.items,
		dataLabel: state => state.data.label,
		dataViewdata: (state, getters, rootState) => {
			let viewdata = { ...state.data.form.viewdata }

			// bank value
			if(viewdata.bankMasterId) {
				let selectedBank = rootState.BankMaster.items.filter(bank => bank.value == viewdata.bankMasterId)
				viewdata.bankMasterId = selectedBank[0].label
			}
			return viewdata
		},
		formLoading: state => state.form.loading,
		formItems: (state, getters, rootState) => {
			let formItems = { ...state.form.items }
			
			// bank value
			if(formItems.bankMasterId) {
				let selectedBank = rootState.BankMaster.items.filter(bank => bank.value == formItems.bankMasterId)
				formItems.bankMasterId_value = selectedBank[0].label
			}
			return formItems
		},
		formLabel: (state, getters, rootState) => {
			let formLabel = state.form.label

			formLabel.default = formLabel.default.map(formLabelDefault => {
				if(formLabelDefault.name == 'bankMasterId') {
					formLabelDefault.options = rootState.BankMaster.items
				}
				return formLabelDefault
			})

			return formLabel
		}
	},
	actions: {
		async fetchData({rootState, commit}) {
			try {
				const response = await DepositService.getAdminFee(rootState.tabledata.form.default)
				commit('setData', response.data.data.rows)
				return response.data.data.count
			} catch (error) {
				commit('setData', null)
				console.log(error)
			}
		},
		async updateDate({commit}, payload) {
			try {
				commit('updateLoading', true)
				let mutatePayload = {
					id: payload.id,
					fixFee: payload.fixFee,
					minimalAmountCashout: payload.minimalAmountCashout,
					feePercentage: payload.feePercentage,
					markupFromFeePercentage: payload.markupFromFeePercentage,
					ppnPercentage: payload.ppnPercentage
				}
				await DepositService.updateAdminFee(mutatePayload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('updateLoading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('updateLoading', false)
			}
		},
		async submitForm({commit}, payload) {
			try {
				commit('loading', true)
				await DepositService.newAdminFee(payload)
				commit('Popup/updateTrigger', 'submitted', { root: true })
				commit('loading', false)
			} catch (error) {
				commit('Popup/updateErrors', error, { root: true })
				commit('loading', false)
			}
		}
	},
	mutations: {
		setData(state, data) {
			state.data.items = data
		},
		loading(state, data) {
			state.form.loading = data
		},
		updateLoading(state, data) {
			state.data.loading.update = data
		},
		setFormItems(state, data) {
			state.form.items = data
		},
		setViewdata(state, id) {
			let getViewdata = state.data.items.filter(dataitems => dataitems.id == id)
			let viewdata = {
				id: helper.getValue(getViewdata[0], 'id'),
				status: helper.getValue(getViewdata[0], 'status'),
				fixFee: helper.getValue(getViewdata[0], 'fixFee'),
				feePercentage: helper.getValue(getViewdata[0], 'feePercentage'),
				markupFromFeePercentage: helper.getValue(getViewdata[0], 'markupFromFeePercentage'),
				minimalAmountCashout: helper.getValue(getViewdata[0], 'minimalAmountCashout'),
				ppnPercentage: helper.getValue(getViewdata[0], 'ppnPercentage'),
				bankMasterId: helper.getValue(getViewdata[0], 'bankMasterId')
			}
			state.data.form.viewdata = viewdata
		}
	}
}
