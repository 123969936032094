<template>
  <NotfoundPage />
</template>

<script>
import NotfoundPage from '@/components/layouts/NotfoundPageComponent.vue'

export default {
  name: 'NotFound',
  components: {
    NotfoundPage
  }
}
</script>