export default [
	{
		path: '',
		name: 'merchant cashout request list',
		component: () => import('@/views/merchantsCashout/MerchantsCashoutList.vue'),
		meta: {
			title: 'Request List',
			isSubmenu: true,
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Merchants Cashout'},
				{ name: 'Request List'},
			]
		}
	},
	{
		path: 'detail/:id',
		name: 'merchant cashout detail',
		component: () => import('@/views/merchantsCashout/MerchantsCashoutDetail.vue'),
		meta: {
			title: 'Merchants Cashout Detail',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Merchants Cashout', link: '/merchants-cashout'},
				{ name: 'Request List', link: '/merchants-cashout'},
				{ name: 'Detail'}
			]
		}
	},
	{
		path: 'manual-transfer',
		name: 'Manual Transfer',
		component: () => import('@/views/merchantsCashout/ManualTransferForm.vue'),
		meta: {
			title: 'Manual Transfer',
			isSubmenu: true,
			roles: ['master_finance'],
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Merchants Cashout'},
				{ name: 'Manual Transfer'}
			]
		},
	},
	{
		path: 'manual-transfer-history',
		name: 'Manual Transfer History',
		component: () => import('@/views/merchantsCashout/ManualTransferList.vue'),
		meta: {
			title: 'Manual Transfer History',
			isSubmenu: true,
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Merchants Cashout'},
				{ name: 'Manual Transfer History'}
			]
		}
	},
	{
		path: 'manual-transfer-history/:id',
		name: 'Manual Transfer History Detail',
		component: () => import('@/views/merchantsCashout/ManualTransferDetail.vue'),
		meta: {
			title: 'Manual Transfer Detail',
			breadcrumb: [
				{ name: 'Home', link: '/'},
				{ name: 'Merchants Cashout', link: '/merchants-cashout/manual-transfer-history'},
				{ name: 'Manual Transfer History', link: '/merchants-cashout/manual-transfer-history'},
				{ name: 'Detail'}
			]
		}
	}
]