import { get } from 'lodash';
const CryptoJS = require("crypto-js");

export default {
	getValue(data, path, attr = null) {
		let defaultValue = attr && attr.defaultValue ? attr.defaultValue : ''
		
		let value = get(data, path, defaultValue)

		// null value
		if(!value || value == 'null') {
			value = defaultValue
		}

		if (attr) {
			// format currency
			if(attr.currency) {
				value = this.formatPrice(value)
			}

			// format percentage
			if(attr.percentage) {
				value = this.formatPercentage(value)
			}
		}
		
		return value

	},
	encrypt(data, isURI = false) {
		let ciphertext = isURI ? CryptoJS.AES.encrypt(data, process.env.VUE_APP_SECRETKEY) : data;
		let cipherURI = encodeURIComponent(ciphertext.toString());
		return cipherURI
	},
	decrypt(data, isURI = false) {
		let decodeURI = isURI ? decodeURIComponent(data) : data;
		let originalText = CryptoJS.AES.decrypt(decodeURI, process.env.VUE_APP_SECRETKEY).toString(CryptoJS.enc.Utf8);
		return originalText
	},
	exportToCSV(data, label) {
		const moment = require('moment');
		const currentDate = moment().format('YYMMDDHmmss');

		let csvText = data
		if(typeof data == 'object') {
			
			const Buffer = require('buffer/').Buffer
			let csvBuffer = new Buffer(data, 'base64');
			csvText = csvBuffer.toString('ascii');
		}

		const blob = new Blob([csvText], { type: 'application/csv' })
		const link = document.createElement('a')
		link.href = URL.createObjectURL(blob)
		link.download = label + currentDate + '.csv'
		link.click()
		URL.revokeObjectURL(link.href)
	},
	formatPrice(value) {
		let val = (value/1).toLocaleString()
		return 'Rp ' + val.toString()
	},
	formatPercentage(value) {
		let val = (value/1).toLocaleString()
		return val.toString() + '%'
	},
	errorValidation(response) {
		return {
			status: true,
			title: response.data || response.errors  ? response.message : "Error",
			subtitle: response.message,
			data: response.data || response.errors
		}
	},
	errorUnknown() {
		return {
			status: true,
			title: 'Error',
			subtitle: 'An error occured, please try again later',
			data: null
		}
	}
}